import { bindable, inject }        from 'aurelia-framework';
import { I18N }                    from 'aurelia-i18n';
import { BaseListViewModel }       from 'base-list-view-model';
import { FilterFormSchema }        from 'modules/management/alert-messages/filter-form-schema';
import { AppContainer }            from 'resources/services/app-container';
import { Downloader }              from 'resources/services/downloader';
import { AlertMessagesRepository } from './services/repository';

@inject(AppContainer, AlertMessagesRepository, I18N, Downloader, FilterFormSchema)
export class ListAlertMessages extends BaseListViewModel {

    listingId = 'management-alert-messages-listing';

    @bindable headerTitle    = 'listing.management.concrete.alert-messages';
    @bindable newRecordRoute = 'management.alert-messages.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.alert-messages.manage',
            'management.alert-messages.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.alert-messages.view', { id: row.id }),
                visible: () => this.appContainer.authenticatedUser.can(['management.alert-messages.manage', 'management.alert-messages.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.alert-messages.edit', { id: row.id }),
                visible: () => this.appContainer.authenticatedUser.can(['management.alert-messages.manage', 'management.alert-messages.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: () => this.appContainer.authenticatedUser.can(['management.alert-messages.manage', 'management.alert-messages.delete']),
            },
            options:         [],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'message',
                    name:  'alert_message_translations.message',
                    title: 'form.field.message',
                },
                {
                    data:  'available',
                    name:  'boolean_options.name',
                    title: 'form.field.available',
                    type:  'label',
                },
                {
                    data:    'created_by',
                    name:    'users.name',
                    title:   'form.field.created-by',
                    display: false,
                },
                {
                    data:            'created_at',
                    name:            'alert_messages.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
